@keyframes shadowRolling {
    0% {
        box-shadow: 0px 0 rgba(0, 0, 0, 0), 0px 0 rgba(0, 0, 0, 0), 0px 0 rgba(0, 0, 0, 0), 0px 0 rgba(0, 0, 0, 0);
    }
    12% {
        box-shadow: 100px 0 lightgrey, 0px 0 rgba(0, 0, 0, 0), 0px 0 rgba(0, 0, 0, 0), 0px 0 rgba(0, 0, 0, 0);
    }
    25% {
        box-shadow: 110px 0 lightgrey, 100px 0 lightgrey, 0px 0 rgba(0, 0, 0, 0), 0px 0 rgba(0, 0, 0, 0);
    }
    36% {
        box-shadow: 120px 0 lightgrey, 110px 0 lightgrey, 100px 0 lightgrey, 0px 0 rgba(0, 0, 0, 0);
    }
    50% {
        box-shadow: 130px 0 lightgrey, 120px 0 lightgrey, 110px 0 lightgrey, 100px 0 lightgrey;
    }
    62% {
        box-shadow: 200px 0 rgba(0, 0, 0, 0), 130px 0 lightgrey, 120px 0 lightgrey, 110px 0 grey;
    }
    75% {
        box-shadow: 200px 0 rgba(0, 0, 0, 0), 200px 0 rgba(0, 0, 0, 0), 130px 0 grey, 120px 0 grey;
    }
    87% {
        box-shadow: 200px 0 rgba(0, 0, 0, 0), 200px 0 rgba(0, 0, 0, 0), 200px 0 rgba(0, 0, 0, 0), 130px 0 grey;
    }
    100% {
        box-shadow: 200px 0 rgba(0, 0, 0, 0), 200px 0 rgba(0, 0, 0, 0), 200px 0 rgba(0, 0, 0, 0), 200px 0 rgba(0, 0, 0, 0);
    }
}

.gia-splash-screen-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 100vh;
}

.gia-splash-screen-wrapper > div > span {
    width: 10px;
    height: 10px;
    display: block;
    border-radius: 50%;
    margin: 15px auto;
    position: relative;
    color: #FFF;
    left: -100px;
    box-sizing: border-box;
    animation: shadowRolling 2s linear infinite;
}


