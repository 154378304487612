@import '../../../styles/sass/bs-custom.scss';
@import '../../../styles/sass/variables.scss';

.GrowattEndUserEdit {
  padding: 2rem;
  :global {
    .header {
      margin-bottom: 2rem;
    }
    .datepicker-container {
      margin-bottom: 1rem;
    }
    .buttons-container {
      margin-top: 1rem;
    }
  }
}
