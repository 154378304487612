@import "../../../styles/sass/variables";

.GiaDatetimePeriodSelector {

  :global {

    label.btn.btn-outline-primary {
      font-weight: 500;
    }

    label.not-allowed {
      cursor: not-allowed;
      pointer-events: all !important;
    }

    .btn {
      --bs-btn-border-color: rgba(0,0,0,0);
      --bs-btn-color: #474851;
      --bs-btn-bg: #EAEAEA;

      --bs-btn-active-bg: #FFFFFF;
      --bs-btn-active-color: #474851;
      --bs-btn-active-border-color: $border-color-default;
    }
  }
}
