.EventConfiguration {
  padding: 2rem;
  position: relative;
  max-width: 100%;

  :global {

    .card,
    .react-loading-skeleton {
      margin-bottom: 2rem;
    }

    .rdt_TableBody {
      min-height: 160px;
    }

    .gia-page-title-default {
      padding-bottom: 24px;
    }
  }

  .datatableWrapper {
    max-width: 100%;
    min-height: 65vh !important;
  }

}