@import "../../../styles/sass/variables";

.Header {

  height: $header-height;
  background-color: #FFFFFF;

  :global {
    .imgLogo {
      height: 64px;
      margin-right: 5rem;
    }
  }
}
