$text-color-default: #3C4043;
$text-color-secondary: #808791;
$text-color-tertiary: #787A89;
$text-color-placeholders: #787A89;

$border-radius-default: 4px;
$border-default: 1px solid #d7d7d7;
$border-color-default: #d7d7d7;
$background-color-default: #e3e3e5;
$menu-color: $text-color-default;
$menu-background-color: #ffffff;
$footer-background-color: $background-color-default;
$letter-spacing-default: -0.014px;
$cancel-buttom-bg: #EFEFEF;

$header-height: 72px;
$footer-height: 40px;

$font-size-default: 14px;

// #Menu
$menu-text-color-active: #176AE3;
$menu-bg-color-active: #E9F2FF;
$menu-bg-color: #F2F2F2;

$page-padding-default: 2rem;
