@import "../../../styles/sass/variables";

.Menu {
  color: $text-color-default;
  background-color: $menu-background-color;
  height: 100%;
  padding-bottom: 1rem;

  :global {

    .menu-wrapper {
      max-width: 100%;
      top: 72px;
    }

    .title-wrapper {
      padding: 2rem 0 1rem 0;
      border-bottom: 1px solid rgba(255,255,255,.1);
    }

    .title-text {
      font-weight: 500;
      font-size: 12px;
      color: $text-color-secondary;
    }

    ul.menu-items {
      padding-left: 0;
      list-style-position: inside;
      list-style-type: none;
      a {
        text-decoration: none;

        li {
          font-size: 14px;
          line-height: 14px;
          color: $text-color-default;
          font-weight: 500;
          border-radius: 0 $border-radius-default $border-radius-default 0;
          white-space: nowrap;

          display: flex;
          justify-content: flex-start;
          align-items: center;
          height: 40px;

          div.icon {
            width: 1.5rem;
            display: inline-flex;
            justify-content: center;
            padding-right: .5rem;
          }

          &::marker {
            font-size: 1.2rem;
          }

          &:hover {
            cursor: pointer;
            background-color: $menu-bg-color;
          }
        }

        &.active > li {
          color: $menu-text-color-active;
          background-color: $menu-bg-color-active;
          font-weight: 500;
        }
      }
    }
  }
}
