@import "../../styles/sass/variables";
@import "../../styles/sass/bs-custom-vars.scss";

button,
input,
textarea,
select {
  box-shadow: none !important;
}

.card {
  background: #ffffff;
  border-radius: 0;
  padding: 24px;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: $border-default;

  .title {
    font-weight: 500;
    font-size: 20px;
    line-height: 20px;
    color: #474851;
    margin-bottom: 8px;
  }
}

.label {
  font-weight: 500;
  font-size: 0.8125rem;
  color: $text-color-secondary;
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  &.required::after {
    content: ' *';
    font-weight: 900;
  }
}

.btn-gia {
  background-color: var(--bs-primary);
  border-color: var(--bs-primary);
  font-size: 14px;

  font-family: Open Sans;
  font-size: 14px;
  font-weight: 500;

  &:hover {
    background-color: var(--bs-primary);
    border-color: var(--bs-primary);
    font-size: 14px;
  }
}

.form-control, .form-select {
  border-radius: 0;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom-width: 1px;
  padding-right: 32px;

  font-family: Open Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  text-align: left;

  &::placeholder {
    color: $text-color-placeholders;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: $letter-spacing-default;
  }
}

.form-select {
  height: 32px;

  font-family: Open Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: inherit;
  text-align: left;
  color: #474851 !important;
  background-position: right .75rem center;
}

.form-select.is-invalid {
  background-position: right .75rem center, center right 2.25rem !important;
}

.form-check-input:disabled {
  opacity: initial;
}

.form-check-input:disabled ~ .form-check-label {
  opacity: initial;
}

.invalid-feedback {
  font-size: .75rem;
  line-height: .75rem;
  padding-left: .75rem;
}

.btn {
  font-weight: 500;
  font-size: $font-size-default;
}

.btn.btn-outline-light {
  color: $text-color-default;
  border-color: $text-color-tertiary;
  background-color: #FFFFFF;
  font-weight: 400;

  &:hover {
    font-size: $text-color-default;
    border-color: #787A89;
    background-color: #f0f0f0;
  }
}

.btn.btn-secondary {
  color: $text-color-default;
  border-color: #EFEFEF;
  background-color: #EFEFEF;

  &:hover {
    color: $text-color-default;
    border-color: #BDBDBD;
    background-color: #BDBDBD;
  }
}

.toast-container {
  position: fixed;
  top: 1rem;
  right: 1rem;
}

.btn-white {
  background-color: #ffffff;
  border: 1px solid #787a89;
}

.form-check-label {
  font-size: $font-size-default;
  color: $text-color-default;
}

.form-check-input {
  margin-top: 6px; // fix this for a relative size, not absolute in px
}
