.Plant {
  padding: 2rem;
  :global {
    .card,
    .react-loading-skeleton {
      margin-bottom: 2rem;
    }
  }

  .edit {
    margin-bottom: 1rem;
    display: flex;
    justify-content: flex-end;
  }
}
