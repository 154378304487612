@import url("../../styles/sass/bs-custom.scss");

.ManagedPlants {
  padding: 2rem;
  position: relative;
  max-width: 100%;

  .dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-top: 5px;
    margin-left: 3px;
  }


  .containerActive {
    min-width: 100px;
  }

  :global {
    .gia-page-title-default {
      padding-bottom: 24px;
    }

    .card,
    .react-loading-skeleton {
      margin-bottom: 2rem;
    }

    .rdt_TableBody {
      min-height: 410px;
    }

    .rdt_TableCell {
      height: 40px;
    }
  }

  .datatableWrapper {
    max-width: 100%;
  }

  div[role="columnheader"] {
    padding-left: 8px;
  }
}
