.EmissionsReduction {

  :global {
    .body {
      display: inline-flex;
      flex: 1;
      justify-content: space-between;
    }
  }

}
