.EmissionsReductionKpiVariant {
  display: flex;
  align-items: flex-end;
  :global {
    .content {
      display: inline-flex;

      .value {
        font-weight: 500;
        font-size: 2rem;
        line-height: 2.625rem;
        color: #2E2E2E;
        margin-left: .5rem;

        .suffix {
          padding-left: .2rem;
          font-size: 1rem;
          line-height: 1.125rem;
        }
      }
      img {
        height: 2.125rem;
      }
    }
  }
}
