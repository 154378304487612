@import "../../styles/sass/bs-custom.scss";
@import "../../styles/sass/variables.scss";

.Customer {
  padding: 2rem;
  position: relative;
  max-width: 100%;

  :global {
    .gia-page-title-default {
      padding-bottom: 24px;
    }
  }
}
