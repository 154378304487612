@import "styles/sass/bs-custom-vars";
@import "~bootstrap/scss/bootstrap";
@import '~react-loading-skeleton/dist/skeleton.css';
@import "~react-datepicker/dist/react-datepicker.css";
@import "styles/css/font-open-sans.css";
@import "styles/sass/variables";

body {
  margin: 0;
  font-family: 'Open Sans', serif;
  scrollbar-gutter: stable;
  letter-spacing: $letter-spacing-default;
  color: $text-color-default;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@import "styles/sass/gia";
@import "styles/sass/bs-custom";
@import "styles/sass/datatable-custom";
@import "styles/sass/react-datepicker-custom";

@import "styles/css/login.css";
@import "styles/css/splash-screen.css";
