@import url("../../../styles/sass/bs-custom.scss");
@import "../../../styles/sass/variables.scss";


.userContainer {
  padding: 2rem;
  position: relative;
  max-width: 100%;
  
  .formContainer {
    height: 100%;
  }

  .form{
    height: 100%;
  }

  .checkContainer{
    height: 32px;
    border-bottom: var(--bs-border-width) solid var(--bs-border-color);
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  .delete{
    margin-left: 1rem;
  }

  .rolesContainer{
    max-height: 105px;
    border-bottom: var(--bs-border-width) solid var(--bs-border-color);
    display: flex;
    justify-content: center;
    flex-direction: column;
    flex-wrap: wrap;

    .roleCheck{
      margin-right: 10px;
    }
  }
}

