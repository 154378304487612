.EnergyGeneratedAtPlantChart {
  .chartWrapper {
    position: relative;
    min-height: 200px;
    height: 40vh;
  }
  .chartStatisticsWrapper {
    margin-top: 1rem;
  }
}
