.EmissionsReductionKpi {
  :global {
    display: flex;
    flex-direction: column;
    .title {
      font-weight: 500;
      font-size: .8125rem;
      line-height: .8125rem;
      color: #7C7C7C;
      margin-bottom: .25rem;
    }

    .content {
      display: inline-flex;

      .value {
        font-weight: 500;
        font-size: 1.75rem;
        line-height: 2.125rem;
        color: #2E2E2E;
        margin-left: .5rem;

        .suffix {
          padding-left: .2rem;
          font-size: .9rem;
          line-height: 1rem;
        }
      }
      img {
        height: 2.125rem;
      }
    }
  }
}
