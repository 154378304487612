.EnergyGeneratedAtPlantSummary {
  padding-bottom: 2rem;
  :global {
    .g-value {
      font-weight: 500;
      font-size: 42px;
      line-height: 42px;

      span {
        font-size: 18px;
        line-height: 18px;
      }
    }

    .g-label {
      font-size: 11px;
      color: #474851;
    }

    .progress-bar-summary-wrapper {
      padding-top: .25rem;
    }

    .last-period-summary {
      .last-period-summary-item {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 60%;

        .progress-bar-wrapper{
          width: 80px;
        }

        .description {
          font-weight: 500;
          font-size: 16px;
          line-height: 16px;
          color: #7C7C7C;
          margin-left: 8px;
          width: 100%;
        }
      }
    }
  }
}
