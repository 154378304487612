@import "../../../styles/sass/variables.scss";

.ImageModal {
  .modalBody {
    height: 45vh !important;
    .container {
      height: 85%;
    }
    img {
      max-width: 100%;
    }
  }
  .containerZoom {
    display: flex;
    span {
      font-size: 14px;
      margin-right: 10px;
    }
    input[type="range"] {
      -webkit-appearance: none;
      width: 100%;
    }
    input[type="range"]:focus {
      outline: none;
    }
    input[type="range"]::-webkit-slider-runnable-track {
      width: 100%;
      height: 3px;
      cursor: pointer;
      background: var(--bs-primary);
      border-radius: 10px;
    }
    input[type="range"]::-webkit-slider-thumb {
      height: 14px;
      width: 14px;
      border-radius: 50px;
      background: var(--bs-primary);
      cursor: pointer;
      -webkit-appearance: none;
      margin-top: -5.5px;
    }
  }
}
