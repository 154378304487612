@import '../../../styles/sass/variables';

.Navbar {
  --bs-border-color: #83756c;

  .title {
    font-weight: 500;
    font-size: 20px;
    line-height: 20px;
    color: #6f625a;
  }

  .photo {
    width: 40px;
    height: 40px;
    border: 1px solid #6f625a;
    border-radius: 50%;
    background-size: cover;
  }

  .logoutDropdown {
    color: #6f625a;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    margin: 0px 12px;
    text-decoration: none;
  }

  .solagora {
    height: 22px;
  }

  .blankPicture {
    background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAYAAACM/rhtAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAKlSURBVHgB7ZjbS5NhHMe/7xRGm9PNedpBR6VJpVZYrSURnYRugqCLKMuo/6rSsoIgspCuiw6Xkc0UbbOd3KF0FzvaFhur510I0TvXfs+z2C72gV3sfX8v++w5/H7P75VcLtdP1DEq1DkNQVHqXrAZgvgDa1j+7EYoFEIilUaTSoXWVh3Mph7YD4/CaGyHCBLvLo7FE3g29wJen79s3NC+vTg/fhZ6fRt44BL8+m0dU/cfIpPNVhRvYHKTE1fQ2WEEFfIalEdueuZRxXJ/PhOLxUGFLCiP3PdMBlSSqRRm2ZKgQhJ0Li6x0aCPwhY+f6D4oUASXFlxQZTFpWVSPEkwyFKJKKseLymeJJhMpiAKdYmQBJuahfM6VCraviRFt+l0EMVqMZHiSYKDgwMQpa+3lxRPEjw4MgRRjh0ZJcWTBC1mMxz2o+BlzGFnNVlPeoZcSc6dPome7i5QkU8342dOgQpZUK1W4+bkBPp376r4mT0D/bh14xqaObKAJNI0zTsX8PL1W8TZYaAUBoMeJ8YcxXMhL1I1urpQJILAWhDZ7I/id61GA7PZhD6rBaJI9d52cpeGXC6HRDLJ6nME+Xwe6c1NFAqF4j1JktDSooV2hwbtbJq72aaiVhAuQfmo5P7igcfrR5hNKwUbS9BWqxmHRoZhYju6UiqaYo/Xh1dv3hUbpGpgZWvzOMunB4b3/zO2rKB8VH/6fK5qYn+z02bDpYsXyjZU2wqub0Rxe3qG7czKew8eDKyyXL96GV2dHSXvl1y58sjduffgv8v9/q04plhDtV0uLSl4V24pORojXtLpNB4/mS15TyE47/zE/g1/Y8SLnOxd7lXF9RKCC6gV7z98VFxTCG5Eo6gVwXBYcU0huFVPa0Emo9yUjfeDojQERal7wV9AwflKIthKPAAAAABJRU5ErkJggg==');
  }

  :global {
    --bs-border-color: #d7d7d7;

    .dropdown {
      .dropdown-toggle {
        margin: 0;
        &.show::after {
          transform: rotate(180deg);
          transition: 0.3s ease;
        }
      }
      .dropdown-toggle::after {
        transition: 0.3s ease;
      }
      .dropdown-menu {
        padding: 0;
        .dropdown-item {
          font-size: 14px;
          font-weight: 500;
          padding: 8px 16px;

          &:hover:nth-child(1) {
            background-color: #e9f2ff;
            color: #1a73e8;
          }
          &:hover:nth-child(2) {
            background-color: #fef0f0;
            color: #d82424;
          }
          svg:nth-child(1) {
            margin-right: 12px;
          }
          svg:nth-child(2) {
            margin-right: 15px;
          }
        }
      }
    }
    .col-logo-2 {
      height: 40px !important;
    }
  }
}
