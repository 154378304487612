.PlantInfo {
  :global {

    .plant-header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .plant-name{
        font-weight: 500;
        font-size: 24px;
        line-height: 24px;
        color: #474851;
      }

      .plant-weather{
        display: flex;
        align-items: center;
      }
    }

    .body {
      display: inline-flex;
      flex: 1;
      justify-content: space-between;

      .item {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        .title {
          font-weight: 500;
          font-size: .8125rem;
          line-height: .8125rem;
          color: #7C7C7C;
          margin-bottom: .25rem;
        }
        .content {
          font-weight: 400;
          font-size: 15px;
          line-height: 15px;
          color: #474851;
          width: 100%;
        }
      }
    }
  }
}
