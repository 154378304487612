@keyframes borealis-bar {
    0% {
        left: 0%;
        right: 100%;
        width: 0%;
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
    }
    10% {
        left: 0%;
        right: 50%;
        width: 50%;
    }
    25% {
        border-radius: 0px;
    }
    50% {
        border-radius: 0px;
    }
    75% {
        border-radius: 0px;
    }
    90% {
        right: 0%;
        left: 50%;
        width: 50%;
    }
    100% {
        left: 100%;
        right: 0%;
        width: 0%;
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
    }
}
