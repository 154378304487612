@import "../../styles/sass/bs-custom.scss";
@import "../../styles/sass/variables.scss";

.plantManager {
  //padding: 2rem;
  :global {
    //.header {
    //  margin-bottom: 2rem;
    //}

    .label-devices{
      margin-top: 1rem;
      margin-bottom: 1rem;
    }

    .btn-add-new-device{
      margin-bottom: 2rem;
    }

    .hiddenCepMessage{
      display: none;
      font-weight: bold;
    }

    .showCepMessage{
      color: red;
    }

    .giaTextField {
      span {
        color: $red;
      }
    }

    .feedbackMessage {
      height: 1rem;
    }

    .allowShutdown-checkbox{
      display: flex;
      justify-content: center; 
      font-size: 14px;
    }
  }
}
