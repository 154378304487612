.GiaChartStatisticKpi {
  &:not(:last-child) {
    margin-right: 2rem;
  }

  .name {
    text-transform: uppercase;
    font-weight: 500;
    font-size: 13px;
    line-height: 13px;
    color: #7C7C7C;
    margin-bottom: 4px;
  }

  .value {
    font-weight: 400;
    font-size: 15px;
    line-height: 15px;
    color: #474851;
  }
}
