@import "styles/sass/variables";

.main-wrapper {
  margin-top: $header-height;
  .header-wrapper {
    background: #FFFFFF;
    border-bottom: $border-default;
  }
  .master-layout {
    .main-container {
      min-height: calc(100vh - $header-height - $footer-height);
      background-color: #FFFFFF;
    }
  }
}
