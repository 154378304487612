.ProgressBarWithPercentage {

  width: 100%;

  :global {
    text-align: center;

    .percentage-value {
      white-space: nowrap;
    }

    .progress-bar-wrapper .progress {
      --bs-progress-height: 6px !important;
    }
  }
}
