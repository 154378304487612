.tags {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
   

    div:nth-child(1) {
      border-radius: var(--bs-btn-border-radius);
      height: 32px;
      span{
        height: 100% !important;
        display: flex !important;
        align-items: center !important;
        font-size: .9rem !important;
        background-color:  #dddddd !important;
        
      }
     
        }
    
        .deleteIcon {
          height: 100%;
          display: flex;
          align-items: flex-start;
        }
        svg {
          height: 1.2vh;
          cursor: pointer;
        }
  }
