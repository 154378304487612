@import "../../styles/sass/variables";

.gia-datatable-wrapper {
  border: none;
  border-radius: $border-radius-default;
  overflow: hidden;

  .gia-datatable {
    max-height: unset;

    .form-check{
      min-height: 0;
      margin-bottom: 0;
    }

    .form-check-input{
      margin-top: 0.25em;
    }
  }

  .rdt_Table {
    .rdt_TableHead {
      .rdt_TableHeadRow{
        color: #3C4043;
        border-bottom: $border-default;
        min-height: 40px;
        .rdt_TableCol {
          .rdt_TableCol_Sortable > div {
            font-size: 14px;
            white-space: nowrap;
            overflow: visible;
            font-weight: 500;
          }
        }
      }
    }

    .rdt_TableBody {
      .rdt_TableRow {
        color: #3C4043;
        border-bottom: $border-default;
        min-height: 40px;
        .rdt_TableCell {
          height: 40px;
          font-size: 14px;
        }
      }
    }
  }

  .rdt_Pagination {
    border-top: none;
    border-bottom: $border-default;
    button {
      border-radius: $border-radius-default;
    }
  }
}
