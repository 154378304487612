@import "variables";
@import "bs-custom-vars";

.gia-search-input-icon {
  color: var(--bs-gray-600);
}

.default-border-color {
  border-color: $border-color-default;
}

.gia-page-title-default {
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
}

.gia-datatable-no-results {
  border-bottom: $border-default;
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: $font-size-default;
}
.gia-message-warning {
  font-size: 14px;
  margin-bottom: .5rem;
}

.gia-message-error {
  font-size: 14px;
  margin-bottom: .5rem;
  color: $red;
}

.gia-form {
  input, select, textarea {
    font-size: 14px;
    color: $text-color-default;
  }

  input:required+label::before,
  select:required+label::before,
  textarea:required+label::before {
    content: " *";
    color: $red;
  }

  .row-buttons-wrapper{
    button:not(:last-child) {
      margin-right: 1rem;
    }
  }
}

.gia-manager, .gia-manager-edit {
  padding: $page-padding-default;
  .header {
    margin-bottom: 1.5rem;
  }
}
.gia-form-feedback-message{
  height: 1rem;
}

.gia-form-field-is-invalid input {
  border-color: var(--bs-form-invalid-border-color);
  padding-right: calc(1.5em + 0.75rem);
  background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 12 12%27 width=%2712%27 height=%2712%27 fill=%27none%27 stroke=%27%23D82424%27%3e%3ccircle cx=%276%27 cy=%276%27 r=%274.5%27/%3e%3cpath stroke-linejoin=%27round%27 d=%27M5.8 3.6h.4L6 6.5z%27/%3e%3ccircle cx=%276%27 cy=%278.2%27 r=%27.6%27 fill=%27%23D82424%27 stroke=%27none%27/%3e%3c/svg%3e');
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.gia-form-field-is-valid {
  input{
    border-color: var(--bs-form-valid-border-color);
    padding-right: calc(1.5em + 0.75rem);
    background-image: url('data:image/svg+xml;charset=utf-8,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 8 8%27%3E%3Cpath fill=%27%23198754%27 d=%27M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z%27/%3E%3C/svg%3E');
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
  }

  .gia-message-error span {
    color: var(--bs-form-valid-border-color);
  }
}

.gia-form-field-warning{
  input {
    border-color: var(--bs-warning);
    padding-right: calc(1.5em + 0.75rem);
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23C9841D'%3E%3Ccircle cx='6' cy='6' r='4.5'/%3E%3Cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3E%3Ccircle cx='6' cy='8.2' r='.6' fill='%23C9841D' stroke='none'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
  }

  .gia-message-error span {
    color: var(--bs-warning);
  }
}
