@import url('../../styles/sass/bs-custom.scss');
@import '../../styles/sass/variables.scss';

.userConfiguration {
  padding: 2rem;

  .cardFull {
    height: 100% !important;

    .titlePreferences {
      margin-bottom: 24px;
    }

    .containerImage {
      margin-bottom: 16px;
      margin-left: 8px;
      .imageOverlay {
        height: 80px;
        width: 80px;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        border-radius: 50%;
        transition: 0.5s ease;
        position: relative;
      }

      .blankUser {
        background-position: bottom;
        background-size: contain;
        background-color: #d9d9d9;
        background-size: 59px;
      }

      .overlay {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #00000080;
        opacity: 0;
        border-radius: 50%;
        cursor: pointer;

        svg {
          height: 3vh;
          color: white;
          transition: 1s;
        }
      }
      :hover {
        opacity: 1;
      }
      .deleteImage {
        position: absolute;
        display: flex;
        justify-content: flex-end;
        margin-left: 4rem;
        background-color: white;
        width: 19px;
        border-radius: 5px;
        svg {
          color: #d82424;
          height: 1.5vh;
          cursor: pointer;
        }
      }
    }
    .changePassword {
      margin-left: 0.05rem;
      button {
        background-color: #ffffff;
        border: 1px solid #787a89;
      }
    }

    .containerButtons {
      font-size: 16px;
      margin-left: 0.05rem;
      button {
        margin-right: 16px;
      }
      button:first-child {
        background-color: var(--bs-primary);
        border-color: var(--bs-primary);
      }
      :nth-child(2) {
        background-color: #efefef !important;
      }
    }

    .notAllowed {
      cursor: not-allowed;
    }
  }
}
.btnCancel {
  background-color: #efefef !important;
}
