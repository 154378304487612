.PersonaEdit {
    padding: 2rem;

    :global {
        .header {
            margin-bottom: 2rem;
        }

        .buttons-container {
            margin-top: 1rem;
        }

        .hiddenCepMessage{
            display: none;
            font-weight: bold;
          }
      
        .showCepMessage{
        color: red;
        }
    }
}