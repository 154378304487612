@import url("../../../styles/sass/bs-custom-vars.scss");

.manageGroupsContainer {
  padding: 2rem;
  position: relative;
  max-width: 100%;
  :global {
    .gia-page-title-default {
      padding-bottom: 24px;
    }
    .react-loading-skeleton {
      margin-bottom: 2rem;
    }
  }
  .datatableWrapper {
    max-width: 100%;
  }
}
