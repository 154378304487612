$red:     #D82424;
$yellow:  #C9841D;
$green:   #0A972A;

$progress-height: 6px;

$progress-border-radius: 4px;
$border-radius: 4px;
$border-radius-sm: 4px;
$border-radius-lg: 4px;
$border-radius-xl: 4px;
$border-radius-2xl: 4px;
$border-radius-pill: 4px;

$input-padding-x: 0 !default;

$toast-background-color: rgba(var(--bs-body-bg-rgb), 1);
$toast-header-background-color: rgba(var(--bs-body-bg-rgb), 1);
