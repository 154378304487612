@import url("../../../styles/sass/bs-custom.scss");
@import "../../../styles/sass/variables.scss";

.schedulerMessageContainer {
  padding: 2rem;
  position: relative;
  max-width: 100%;

  :global {

    .header {
      margin-bottom: 20px;
    }

    .label-groups{
      margin-top: 1rem;
      margin-bottom: 1rem;
    }

    .btn-add-new-group{
      margin-bottom: 2rem;
    }
  }

}

.skeletonContainer {

}

