@import "../../../styles/sass/bs-custom.scss";
@import "../../../styles/sass/variables.scss";

.EventConfigurationEdit {
  padding: 2rem;

  :global {
    .card,
    .react-loading-skeleton {
      margin-bottom: 2rem;
    }

    .rdt_TableBody {
      min-height: 150px;
    }
  }

  .datatableWrapper {
    max-width: 100%;
  }
}

.EventConfigurationForm {
  width: 100%;
  padding-top: 1rem;
  background-color: #ffffff;

  .UploadDmn {
    cursor: pointer;
    svg {
      height: 2vh;
    }
    span {
      vertical-align: super;
      color: $text-color-placeholders;
    }
    a {
      vertical-align: super;
      text-decoration: none;
      color: var(--bs-body-color);
    }
  }

  select {
    padding-top: 5px;
  }
  .saveContainer {
    button {
      margin-right: 1rem;
    }
  }
  .checkContainer {
    height: 32px;
    border-bottom: var(--bs-border-width) solid var(--bs-border-color);
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
  }

  .border {
    border-bottom: var(--bs-border-width) solid var(--bs-border-color);
  }
}
