@import "../../styles/sass/bs-custom.scss";
@import "../../styles/sass/bs-custom-vars";
@import "../../styles/sass/variables.scss";

.form{
  padding: 0 !important;
}


.giaTextField {
  span {
    color: $red;
  }
}

.giaSwitch {
  input[type="checkbox"]:checked {
    background-color: var(--bs-primary);
    cursor: pointer;
  }
}

.feedbackMessage {
  height: 1rem;
}
