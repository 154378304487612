@import "../../../styles/sass/variables";

.Footer {
  :global {

    .footer-container {

      font-size: 12px;
      line-height: 12px;
      color: $text-color-secondary;
      border-top: $border-default;
      height: $footer-height;
      display: flex;
      align-items: center;
      justify-content: center;

      a {
        text-decoration: none;
        color: $text-color-secondary;
        &:hover {
          text-decoration: underline;
        }
      }

      .separator {
        margin-left: 1rem;
        margin-right: 1rem;
      }
    }
  }
}
