@import url("../../../styles/sass/bs-custom.scss");
@import "../../../styles/sass/variables.scss";


.robbuGroupContainer {
  padding: 2rem;
  position: relative;
  max-width: 100%;
  
  .formContainer {
    height: 100%;
  }

  .form{
    height: 100%;
  }

  .checkContainer{
    border-bottom: var(--bs-border-width) solid var(--bs-border-color)
  }

  .delete{
    margin-left: 1rem;
  }

  .containerButtons{
    margin-top: 8rem;
  }
}