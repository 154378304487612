@import "../../styles/sass/variables";
@import "~bootstrap/scss/variables";

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker {
  font-size: 14px;
  color: $text-color-secondary;
  border: $border-default;

  .react-datepicker__header {
    background-color: $background-color-default;
    .react-datepicker__current-month, .react-datepicker__day-name {
      color: $text-color-default !important;
    }
  }

  .react-datepicker__time-container {
    .react-datepicker__header.react-datepicker__header--time {
      color: $text-color-default !important;
    }

    .react-datepicker__time-list-item.react-datepicker__time-list-item--selected {
      color: #FFFFFF !important;
      background-color: $primary !important;
    }
  }

  .react-datepicker__month{
    .react-datepicker__day {
      color: $text-color-secondary !important;

      &.react-datepicker__day--selected {
        color: #FFFFFF !important;
        background-color: $primary !important;
      }
    }

  }
}
